import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

const ProductThumbnail = ({
  linkToProductPage,
  imageThumbnail,
  name,
  price,
}) => {
  return (
    <React.Fragment>
      <Link to={linkToProductPage}>
        <div className="d-flex flex-column">
          <Img fluid={imageThumbnail.childImageSharp.fluid} />
          <h4 className="h6 text-muted mt-3">{name}</h4>
          <p className="text-muted">
            <small>{price}</small>
          </p>
        </div>
      </Link>
    </React.Fragment>
  )
}

export default ProductThumbnail

ProductThumbnail.defaultProps = {
  price: "Rp200,000",
}
