import React from "react"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import ProductThumbnail from "../components/global/productThumbnail"

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

const ProductPageTemplate = ({ data }) => {
  const relatedProductArray = shuffle(data.forRelatedProduct.edges.slice(0, 4))
  return (
    <React.Fragment>
      <SEO title={data.product.name} />
      <Layout>
        <div className="container my-5 py-5">
          <div className="row">
            <div className="col-12 col-md-6">
              <Img
                style={{ minHeigth: "100%" }}
                fluid={data.product.childImageSharp.fluid}
              />
            </div>
            <div className="col-12 col-md-6">
              <nav>
                <ol className="breadcrumb bg-transparent mx-0 px-0">
                  <li className="breadcrumb-item">
                    <Link className="text-muted" to="/">
                      <small>Home</small>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link className="text-muted" to="/shop">
                      <small>Shop</small>
                    </Link>
                  </li>
                </ol>
              </nav>
              <h1>{data.product.name}</h1>
              <h2>Rp 200.000</h2>
              <p className="text-muted">
                Menggunakan bahan Breathable Lycra yang dikombinasikan dengan
                bahan Super-Stretch Polyester dan detail tulisan “REYD” berbahan
                Polyflex di bagian depan sebelah kanan dan Polyflex Reflektif di
                sebelah kanan belakang bawah.
              </p>
              <div className="form-group">
                <label className="text-uppercase">Sizes</label>
                <select className="form-control">
                  <option>Choose an option</option>
                  <option>M</option>
                  <option>XL</option>
                </select>
              </div>
              <div className="d-flex justify-content-start my-5">
                <div style={{ width: "5rem" }} className="border-bottom">
                  <input className="form-control border-0" type="number" />
                </div>
                <div>
                  <button
                    className="btn btn-dark border-none mx-5 px-5"
                    type="submit"
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
              <div className="mt-5">
                <p className="text-muted">Category: Hijab</p>
              </div>
            </div>
          </div>
          <div className="my-5 py-5">
            <h2 className="mx-auto h4 mb-5 text-center">Description</h2>
            <p>Panjang Produk : 15 cm (diukur dari leher ke bawah)</p>
            <p>Lebar Produk : 55 cm</p>{" "}
            <p>Diameter Produk : 55 cm (diameter kepala)</p>
            <p>Material : Breathable Lycra</p>
            <p>
              Cara Styling :
              <ul>
                <li> Digunakan sebagai jilbab bagian luar</li>
                <li>
                  Dijadikan jilbab untuk bagian dalam (gunakan jilbab dulu baru
                  baju)
                </li>
              </ul>
            </p>
            <p>
              Cara Perawatan :
              <ul>
                <li> Dapat dicuci dengan mesin, dengan suhu normal</li>
                <li>Saat dicuci sebaiknya dipisah dengan warna berbeda</li>
                <li>
                  Dapat diseterika dengan suhu medium, jangan menyetrika bagian
                  sablon
                </li>
                <li>
                  Jangan menggunakan pemutih Gunakan dry clean Perchloroethylene
                </li>
              </ul>
            </p>
          </div>
          <section>
            <h3>Related Products</h3>

            <div className="row my-5">
              {relatedProductArray.map(product => {
                return (
                  <div className="col-12 col-md-6 col-lg-3">
                    <ProductThumbnail
                      name={product.node.name}
                      imageThumbnail={product.node}
                      linkToProductPage={`/shop/${product.node.name
                        .toLowerCase()
                        .replace(/ /g, "-")}`}
                    />
                  </div>
                )
              })}
            </div>
          </section>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default ProductPageTemplate

export const query = graphql`
  query($name: String) {
    product: file(name: { eq: $name }) {
      name
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    forRelatedProduct: allFile(
      filter: { relativeDirectory: { eq: "shopPage" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
